.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #f8f9fa;
}

.audio {
  width: 100%;
  margin-top: 15px;
}

/* App.css */

/* Style for the range slider track */
.custom-range::-webkit-slider-runnable-track {
  background: blue;
}

.custom-range::-moz-range-track {
  background: blue;
}

.custom-range::-ms-track {
  background: blue;
}

/* Style for the range thumb (handle) */
.custom-range::-webkit-slider-thumb {
  border: 1px solid blue;
}

.custom-range::-moz-range-thumb {
  border: 1px solid blue;
}

.custom-range::-ms-thumb {
  border: 1px solid blue;
}
[contentEditable][data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #999; /* Placeholder text color */
  pointer-events: none; /* Ensure the placeholder is not interactable */
  display: block; /* If the contentEditable is inline */
}

.gear-icon {
  position: absolute; /* Changed from 'fixed' to 'absolute' */
  right: 10px;
  top: 10px;
  font-size: 16px; /* Smaller size */
  color: lightgray; /* Light gray color */
  /* cursor: pointer; */
}

.stageone-btn {
  background-color: #f05f40ff; /* Normal state */
  color: white;
  border-color: #f05f40ff; /* Normal state */
  border-width: 1px;
}

.stageone-btn:hover {
  background-color: #d94e36; /* Slightly darker shade for hover */
  border-color: #d94e36;
}

.stageone-btn:active {
  background-color: #bf4430 !important; /* Even darker for active click */
  border-color: #bf4430 !important;
}

.stageone-btn:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 95, 64, 0.5); /* Focus shadow with transparency */
}


.stageone-outline-btn {
  background-color: transparent; /* No background fill */
  color: black; /* Black text */
  border-color: #f05f40ff; /* Use the custom color for border */
  border-width: 1px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s; /* Smooth transition for visual effect */
}

.stageone-outline-btn:hover {
  background-color: #f05f40ff; /* Fill background on hover */
  color: white; /* White text on hover */
  border-color: #d94e36; /* Slightly darker shade for border on hover */
}

.stageone-outline-btn:active {
  background-color: #bf4430; /* Even darker for active click */
  border-color: #bf4430;
  color: white; /* Ensure text is white when active for visibility */
}

.stageone-outline-btn:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 95, 64, 0.5); /* Focus shadow with transparency */
}


.bottom-tabs .nav-tabs {
  order: 2; /* Place the navigation below the tab content */
  border-bottom: none; /* Remove default bottom border */
}

.bottom-tabs .nav-link.active {
  border-bottom: 2px solid #0d6efd; /* Highlight the active tab with a top border */
  border-top: none; /* Remove bottom border */
  border-left: none; /* Remove left border */
  border-right: none; /* Remove right border */
}

.bottom-tabs .nav-tabs .nav-link {
  border-bottom: none; /* Remove borders for non-active tabs */
}

.bottom-tabs .tab-content {
  order: 1; /* Ensure content is above the tabs */
  border-top: none; /* Remove border at the top of the tab content */
}

.animated-outline {
  position: relative;
  animation: outline-animation 2s infinite;
}

@keyframes outline-animation {
  0%, 100% {
    border: 2px solid gray;
  }
  50% {
    border: 2px solid blue;
  }
}


.orange-highlight::selection {
  background-color: #f05f40ff;
  color: white;
}

.blue-tabs .nav-link.active {
  background-color: #0d6efd;
  color: white; /* Optional: To ensure the text color contrasts well with the background */
}
